.single-content-block {
    width: 100%;
    height: 620px;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--primary-text-color);
    background-color: var(--background-color);
    @media (max-width: 750px) {
        display: block;
    }
}

.single-content-block-container {
    position: absolute;
    width: 65%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 750px) {
        position: relative;
        right: unset;
        width: 100%;
        height: 60%;
        float: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.single-content-block-container-left {
    float: right;
    right: 0;
}

.single-content-block-container-right {
    float: left;
    left: 0;
}

.single-content-block-info-container {
    position: absolute;
    width: 35%;
    z-index: 2;
    @media (max-width: 1200px) {
        width: 50%;
    }
    @media (max-width: 760px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: unset;
        right: unset;
    }
}

.single-content-block-info-container-left-top {
    left: 10%;
    top: 10%;
    text-align: start;
}

.single-content-block-info-container-left-center {
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 760px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: unset;
        right: unset;
    }
}

.single-content-block-info-container-left-bottom {
    left: 10%;
    bottom: 10%;
    text-align: start;
    @media (max-width: 760px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: unset;
        right: unset;
    }
}

.single-content-block-info-container-center-top {
    top: 10%;
    left: 0;
    right: 0;
    margin-inline: auto;
    text-align: center;
}

.single-content-block-info-container-center-center {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    text-align: center;
    @media (max-width: 760px) {
    }
}

.single-content-block-info-container-center-bottom {
    bottom: 10%;
    left: 0;
    right: 0;
    margin-inline: auto;
    text-align: center;
    @media (max-width: 760px) {
        left: unset;
    }
}

.single-content-block-info-container-right-top {
    right: 10%;
    top: 10%;
    text-align: end;
}

.single-content-block-info-container-right-center {
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;
    align-items: end;
}

.single-content-block-info-container-right-bottom {
    right: 10%;
    bottom: 10%;
    text-align: end;
    @media (max-width: 760px) {
        left: unset;
    }
}

.single-content-block-thumbnail-left {
    width: 160px;
    height: 90px;
    margin-bottom: 40px;
    border-radius: 12px;
    @media (max-width: 760px) {
        margin-bottom: 20px;
    }
}

.single-content-block-title-left {
    font-size: var(--font-size-display-sm);
    /* color: var(--primary-text-color); */
    /* text-align: left; */
    margin-bottom: 40px;
    @media (max-width: 760px) {
        margin-bottom: 20px;
        font-size: var(--font-size-title);
        text-align: center;
    }
}

.single-content-block-sub-title-left {
    font-size: var(--font-size-heading);
    /* color: var(--primary-text-color); */
    /* text-align: left; */
    line-height: 33.6px;
    margin-bottom: 40px;
    font-weight: 400;
    width: 620px;
    @media (max-width: 760px) {
        font-size: var(--font-size-subtitle-md);
        white-space: normal;
        overflow-wrap: break-word;
        justify-content: center;
        align-items: center;
        width: 95%;
        text-align: center;
    }
}

.single-content-block-btn {
    display: flex;
    border-radius: 24px;
    cursor: pointer;
    border: none;
    font-size: var(--font-size-body-sm);
    line-height: 22.4px;
    font-weight: 700 !important ;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    padding-left: 32px;
    padding-right: 32px;
    width: fit-content;
    background-color: var(--primary-color);
    font-family: var(--font-name-bold);
    margin-top: 16px;
}

.single-content-block-btn:hover {
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    transition: 0.4s;
}

.single-content-block-btn-left {
    display: flex;
    justify-content: left;
    @media (max-width: 750px) {
        justify-content: center;
    }
}
.single-content-block-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 750px) {
        justify-content: center;
    }
}
.single-content-block-container-video {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    float: right;
    background-size: cover;
    background-repeat: no-repeat;
}
.single-content-block-btn-right {
    display: flex;
    justify-content: end;
    @media (max-width: 750px) {
        justify-content: center;
    }
}

.single-content-price-label {
    font-size: var(--font-size-body-lg);
    margin-top: 15px;
}

.single-content-block-login-btn-left:hover {
    transition: ease-out 0.3s;
    /* background-color: var(--background-color); */
    color: black;
}

.single-content-block-video {
    width: 100%;
    height: 620px;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}
.video-overlay {
    z-index: 1;
    position: relative;
    height: 100%;
    opacity: 0.5;

    @media (max-width: 750px) {
        height: 100%;
    }
}

/* .single-content-block-right {
    width: 65%;
    height: 100%;
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 750px) {
        width: 100%;
        height: 60%;
        float: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
} */

/* .single-content-block-info-container-right {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    width: 36%;
    right: 0px;
    padding-right: 10%;

    @media (max-width: 1200px) {
        width: 50%;
    }

    @media (max-width: 760px) {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: unset;
        bottom: 0px;
        padding-bottom: 40px;
        padding-right: unset;
    }
} */

/* .single-content-block-icon-right {
    width: 160px;
    height: 90px;
    border-radius: 12px;
    margin-bottom: 40px;
    object-fit: fill;
    @media (max-width: 760px) {
        margin-bottom: 20px;
    }
} */

/* .single-content-block-heading-right {
    font-size: 42px;
    color: var(--primary-text-color);
    text-align: right;
    @media (max-width: 760px) {
        margin-bottom: 20px;
        font-size: 32px;
        text-align: center;
    }
} */

/* 
.single-content-block-sub-title-right {
    font-size: 23px;
    color: var(--primary-text-color);
    text-align: right;
    @media (max-width: 760px) {
        font-size: 21px;
        text-align: center;
    }
} */

/* .single-content-block-login-btn-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    border-style: solid;
    border-color: white;
    margin-top: 50px;
    font-size: 23px;
    color: var(--primary-text-color);
    float: right;
    @media (max-width: 760px) {
        margin-top: 40px;
    }
} */

/* .single-content-block-login-btn-right:hover {
    transition: ease-out 0.3s;
    background-color: var(--background-color);
    color: black;
} */
